<template lang="">
  <b-overlay
    :show="show"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
    rounded="sm"
  >
    <b-row>
      <b-col
        cols="12"
        xl="8"
      >
        <b-card class="h-100">
          <ValidationObserver v-slot="{ invalid }">
            <b-form @submit.prevent="createMetaAcc">
              <b-row>
                <b-col
                  cols="12"
                  sm="6"
                  class="mt-1"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    :name="$t('account_type')"
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('account_type')"
                      label-size="lg"
                      label-class="font-weight-bold pt-0"
                      class="mb-0"
                    >
                      <b-form-select
                        v-model="accountTypeSelected"
                        :options="metaGroups"
                        value-field="id"
                        text-field="name"
                      />
                    </b-form-group>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-col>
                <b-col
                  cols="12"
                  sm="6"
                  class="mt-1"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    :name="$t('leverage')"
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('leverage')"
                      label-size="lg"
                      label-class="font-weight-bold pt-0"
                      class="mb-0"
                    >
                      <b-form-select
                        v-model="leverageSelected"
                        :options="metaLeverages"
                        value-field="id"
                        text-field="name"
                      />
                    </b-form-group>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="12"
                  sm="6"
                  md="4"
                  class="mt-1"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    :name="$t('password_investor')"
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('password_investor')"
                      label-size="lg"
                      label-class="font-weight-bold pt-0"
                      class="mb-0"
                    >
                      <b-form-input
                        id="password_investor"
                        v-model="password_investor"
                        type="password"
                      />
                    </b-form-group>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-col>
                <b-col
                  cols="12"
                  sm="6"
                  md="4"
                  class="mt-1"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    :name="$t('password_main')"
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('password_main')"
                      label-size="lg"
                      label-class="font-weight-bold pt-0"
                      class="mb-0"
                    >
                      <b-form-input
                        id="password_main"
                        v-model="password_main"
                        type="password"
                      />
                    </b-form-group>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-col>
                <b-col
                  cols="12"
                  sm="6"
                  md="4"
                  class="mt-1"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    :name="$t('recovery_password')"
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('recovery_password')"
                      label-size="lg"
                      label-class="font-weight-bold pt-0"
                      class="mb-0"
                    >
                      <b-form-input
                        id="recovery_password"
                        v-model="recovery_password"
                        type="password"
                      />
                    </b-form-group>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-col>
              </b-row>

              <b-row
                align-h="start"
                class="mt-2"
              >
                <b-col cols="auto">
                  <b-button
                    type="submit"
                    variant="primary"
                    class="flex-end"
                    style="min-width:150px"
                    :disabled="invalid"
                    :loading="isCreateAccount"
                  >
                    <span v-if="isCreateAccount">
                      <b-spinner
                        variant="light"
                        type="grow"
                        small
                      />
                    </span>
                    <span v-else>
                      {{ $t('create') }}
                    </span>
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </ValidationObserver>
        </b-card>
      </b-col>

      <b-col
        cols="12"
        xl="4"
        class="mt-2 mt-xl-0"
      >
        <b-card
          class="h-100"
          style="text-align:justify"
        >
          <b-row>
            <b-col>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi a iure repudiandae excepturi pariatur rem beatae minima suscipit exercitationem dignissimos.
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi a iure repudiandae excepturi pariatur rem beatae minima suscipit exercitationem dignissimos.
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi a iure repudiandae excepturi pariatur rem beatae minima suscipit exercitationem dignissimos.
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi a iure repudiandae excepturi pariatur rem beatae minima suscipit exercitationem dignissimos.
            </b-col>
          </b-row>

        </b-card>
      </b-col>
    </b-row>

    <b-row
      v-if="Object.keys(accCards).length !== 0"
      class="mt-2"
    >
      <b-col cols="12">
        <b-card>
          <h2 class="mt-1 mb-2">
            {{ $t('info_metatrader_acc_header') }}
          </h2>
          <b-row

            class="mt-3"
          >
            <b-col
              v-for="item in accCards"
              :key="item.id"
              xs="12"
              sm="12"
              md="6"
              xl="4"
            >
              <MetaAccountCard
                :acc="item"
              />
            </b-col>
          </b-row>
          <!-- <b-row
            v-else
          >
            <b-col>
              <h3 class="text-center">
                {{ $t('no_item_exist') }}
              </h3>
            </b-col>
          </b-row> -->
        </b-card>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BFormSelect,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import { required } from '@validations'
import MetaAccountCard from '@/views/components/metatrader/MetaAccountCard.vue'
import MetatraderService from '@/services/apis/metatrader'

const {
  getAllAccounts, getLeverages, getGroups, registerAccount,
} = new MetatraderService()

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BForm,
    BFormSelect,
    BButton,
    BSpinner,
    MetaAccountCard,
  },
  data() {
    return {
      required,
      show: true,
      leverageSelected: null,
      accountTypeSelected: null,
      recovery_password: '',
      password_main: '',
      password_investor: '',
      isCreateAccount: false,
      metaAccounts: null,
      metaLeverages: null,
      metaGroups: null,
      accountType: [
        { value: null, text: 'Please select an option' },
        { value: 'a', text: 'This is First option' },
        { value: 'b', text: 'Selected Option' },
        { value: { C: '3PO' }, text: 'This is an option with object value' },
        { value: 'd', text: 'This one is disabled' },
      ],
      accCards: [
        {
          id: 1,
          name: 'nateghi',
          balance: 30000,
          margin: 'test',
        },
        {
          id: 2,
          name: 'shokouhi',
          balance: 10000,
          margin: 'test',
        },
        {
          id: 3,
          name: 'shokouhi',
          balance: 10000,
          margin: 'test',
        },
      ],
    }
  },
  async created() {
    await getAllAccounts().then(res => {
      this.metaAccounts = res.data.results
    })
    await getLeverages().then(res => {
      this.metaLeverages = res.data.results
    })
    await getGroups().then(res => {
      this.metaGroups = res.data.results
    })

    this.show = false
  },

  methods: {
    createMetaAcc() {
      this.isCreateAccount = true

      const newAcc = {
        main_password: this.password_main,
        investor_password: this.password_investor,
        phone_password: this.recovery_password,
        group_id: '',
        leverage_id: '',
      }

      registerAccount(newAcc).then(res => {
        console.log(res)
      }).finally(() => {
        this.isCreateAccount = false
      })
    },
  },
}
</script>
