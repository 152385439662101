import axiosIns from '@/libs/axios'

export default class MetatraderApis {
  getAllAccounts = async () => await axiosIns.get('/auth/mt4webregister/accounts');

  getLeverages = async () => await axiosIns.get('/auth/mt4webregister/leverages');

  getGroups = async () => await axiosIns.get('/auth/mt4webregister/groups');

  registerAccount = async data => await axiosIns.post('/auth/mt4webregister/register', data);
}
