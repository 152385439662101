<template>
  <b-card
    style="max-width:450px; box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.06) !important; background-color:rgb(231 219 255)"
    class="rounded mx-auto"
  >
    <b-row align-h="between">
      <b-col cols="auto">
        <h3 class="mt-1">
          {{ acc.name }}
        </h3>
      </b-col>
      <b-col cols="auto">
        <h4 class="mt-1">
          {{ acc.balance }}
        </h4>
        <span class="dollar">$</span>
      </b-col>
    </b-row>
    <b-row
      align-h="end"
      class="my-2"
    >
      <b-col
        cols="auto"
        class="mx-auto"
      >
        {{ acc.margin }}
      </b-col>
    </b-row>
    <b-row class="mt-3 flex-nowrap">
      <b-col
        cols="auto"
      >
        <b-button
          variant="primary"
          size="sm"
          :to="`/${$i18n.locale}/metatrader/history/${acc.id}`"
        >
          {{ $t('history') }}
        </b-button>
      </b-col>
      <b-col
        cols="auto"
      >
        <b-button
          variant="outline-primary"
          size="sm"
          :to="`${$i18n.locale}/metatrader/history/${acc.id}`"
        >
          {{ $t('history') }}
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
  },
  props: {
    acc: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {}
  },
}
</script>

<style scoped>
.dollar{
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 3rem;
  opacity: .3;
  border-radius: 50%;
  border: 1px solid rgb(199, 146, 146);
  width: 40px;
  height: 40px;
}
</style>
